<template>
    <div>
        <div class="bread-crumbs">
            <div class=" pw">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>{{this.$route.query.tag==2?'按赛道分类':'按类型分类'}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <!-- 标签栏 -->
        <div class="ctrl-panel-archive">
            <div class="filter-panel">
                <dl class="filter-items checkbox-mode" v-for="(item,index) in ficaList" :key="index">
                    <dt>
                        选择{{item.type}}:
                    </dt>
                    <dd class="primary" v-show="item.type=='类型'">
                        <a v-for="(items,indexs) in item.tag" :key="indexs" class="aft-item" :class="items.state==1?'selected':''" @click="choose(items,index)">
                            <span>
                                {{items.name}}
                            </span>
                        </a>
                    </dd>
                    <dd class="primary" v-show="item.type=='风格'">
                        <a v-for="(items,indexs) in item.tag" :key="indexs" class="aft-item" :class="items.state==1?'selected':''" @click="chooses(items,index)">
                            <span>
                                {{items.name}}
                            </span>
                        </a>
                    </dd>
                    <dd class="primary" v-show="item.type=='模块'">
                        <a v-for="(items,indexs) in item.tag" :key="indexs" class="aft-item" :class="items.state==1?'selected':''" @click="choosess(items,index)">
                            <span>
                                {{items.name}}
                            </span>
                        </a>
                    </dd>
                </dl>
            </div>
            <div class="sorting-bar">
                <ul class="filter-items">
                    <li class="filter-item" :class="currnum==1?'current':''" @click="currss(1)">
                        <span>最新</span>
                        <i class="arr-more"></i>
                    </li>
                    <li class="filter-item" :class="currnum==2?'current':''" @click="currss(2)">
                        <span>热门</span>
                        <i class="arr-more"></i>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 内容 -->
        <div class="articles-list">
                <article class="post" v-for="(item,index) in artiList.slice((currentPage-1)*pageSize,currentPage*pageSize)" :key="index" v-show="$route.query.type==1">
                    <div class="inner" @click="jump(1,item)">
                        <div class="media-pic">
                            <i class="tag-vip tag-vips"></i>
                            <img :src="item.cover" width="600" height="400" alt="" class="wp-post-image">
                            <a class="post-cate">
                                <em class="tag-213">
                                    演示版
                                </em>
                            </a>
                        </div>
                        <div class="clearfix">
                            <p class="post-date fl">
                                <span class="" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div class="media-body clearfix">
                            <a class="title fl" :title="item.name">
                                <span class="title_span" v-show="item.animation!=0">{{item.animation==1?'路演':item.animation==2?'网评':''}}</span>
                                {{item.name}}
                            </a>
                            <p class="post-meta fr">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </p>
                        </div>
                    </div>
                </article>
                <article class="post posts" v-for="(item,index) in artiList.slice((currentPage-1)*pageSize,currentPage*pageSize)" :key="'info-'+ index" v-show="$route.query.type==2">
                    <div class="wode_iner" @click="jump(2,item)">
                        <i class="tag-vip"></i>
                        <img :src="item.cover" alt="">
                        <a class="post-cate">
                            <em class="tag-213">
                                文档版
                            </em>
                        </a>
                        <div class="flex-ccj">
                            <a :title="item.name">{{item.name}}</a>
                            <div class="flex-ca clearfix">
                                <span class="fl" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </div>
                            <div class="flex_div fl">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </div>
                            <div class="mix_back">
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </article>
                <div class="nodata" v-show="artiList.length==0">
                    <img src="./../../assets/stw049.png" alt="">
                </div>
            </div>
            <!-- 分页 -->
            <div class="pagination" v-show="artiList.length!=0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    prev-text="上一页"
                    next-text="下一页"
                    background
                    layout="prev, pager, next"
                    :total="artiList.length">
                </el-pagination>
            </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ficaList:[],
            pageSize:12,
            istrue:0,
            currnum:1,
            artiList:[],
            currentPage:1,
            totalnum:15,
            itemName:'',
            tag4:'',
            sort:'new',
            tag1:''
        }
    },
    methods: {
        getapp(){
            this.axios.indexPageindex({
                flag:1,
                tag:this.$route.query.tag,
                id:this.$route.query.tagId,
                sort:this.sort,
                type:this.$route.query.type,
                tag4:this.tag4,
                tag1:this.tag1
            }).then(res=>{
                if(res.data.code==0){
                    let array = []
                    res.data.data.menu.map((item)=>{
                        array.push(Object.assign({},item,{istrue:0})) 
                    })
                    this.ficaList = array
                    if(this.$route.query.type==1){
                        this.artiList = res.data.data.ppt
                    }else{
                        this.artiList = res.data.data.word
                    }
                }else{
                    this.ficaList = []
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        //跳转至详情页
        jump(val,item){
            let link = this.$router.resolve({name:'Detailspage',query:{type:val,code:item.code}})
            window.open(link.href,'_blank')
        },
        currss(val){
            this.currnum  = val
            if(val==1){
                this.sort = 'new'
            }else{
                this.sort='hot'
            }
            this.getapp()
        },
        choose(item,index){
            this.$router.push({name:'PPThome',query:{
                tagId:this.$route.query.tagId,tag:this.$route.query.tag,itemName:this.$route.query.itemName,type:item.id
            }})
            location.reload()
        },
        chooses(item,index){
            this.tag4 = item.id
            this.getapp()
        },
        choosess(item,index){
            this.tag1 = item.id
            this.getapp()
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            document.documentElement.scrollTop = 0;  //ie下
            document.body.scrollTop = 0;  //非ie
            this.currentPage = val;
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../styles/Page/PPThome.css';
@import './../../styles/Page/PageHome.css';
</style>
<style>
.pagination .el-pagination.is-background .el-pager li:not(.disabled).active{
    border: 1px solid #58abe0;
    background: rgba(88,171,224,.05);
    color: #58abe0;
}
.el-pagination button{
    height: 32px;
    line-height: 32px;
}
.el-pagination button, .el-pagination span:not([class*=suffix]){
    height: 32px;
    line-height: 32px;
}
.pagination .el-pagination .btn-prev{
    padding: 0 15px;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
    min-width: 32px!important;
}
.pagination .el-pagination .btn-next{
    padding: 0 15px;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
    min-width: 32px!important;
}
.pagination .el-pager li{
    padding: 0;
    font-size: 13px;
    min-width: 32px!important;
    height: 32px;
    line-height: 32px;
    box-sizing: border-box;
    text-align: center;
}
.pagination .el-pagination.is-background .btn-prev,.pagination .el-pagination.is-background .btn-next, .pagination .el-pagination.is-background .el-pager li{
    background: transparent;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
}
/* .pagination .el-pagination button:disabled{
    display: none;
} */
</style>